import React from "react"
import PropTypes from "prop-types"

import DynamicSvg from "../../primitives/DynamicSvg"
import {
  MiddleRight1,
  MiddleRight2,
  MiddleRight3,
  MiddleRightStatic1,
  MiddleRightStatic2,
} from "../../primitives/Icons"

import { Wrapper, Button, ButtonsWrapper } from "./Controls.styles"

const buttons = [
  {
    text: "Company",
    color: "#0052e5",
  },
  {
    text: "Student",
    color: "#222bb0",
  },
  {
    text: "Mentor",
    color: "#38175b",
  },
]

const Controls = ({ activeIndex, setClickIndex }) => {
  return (
    <Wrapper>
      <DynamicSvg
        svgArr={[<MiddleRight1 />, <MiddleRight2 />, <MiddleRight3 />]}
        activeIndex={activeIndex}
        styleObj={{
          position: "absolute",
          zIndex: "3",
          top: "140px",
          right: "-170px",
        }}
      />
      <MiddleRightStatic1
        style={{
          top: `-60px`,
          right: `-140px`,
          zIndex: `1`,
          position: "absolute",
        }}
      />
      <MiddleRightStatic2
        style={{
          bottom: `-650px`,
          right: `-160px`,
          zIndex: `2`,
          position: "absolute",
        }}
      />
      <div className="tagline">
        <p>I am a:</p>
      </div>
      <ButtonsWrapper>
        {buttons.map((obj, i) => (
          <Button
            key={`control-${i}`}
            role="button"
            tabIndex={0}
            bgColor={i === activeIndex && obj.color}
            borderColor={obj.color}
            onClick={() => setClickIndex(i)}
            className={i === activeIndex ? "active" : ""}
          >
            {obj.text}
          </Button>
        ))}
      </ButtonsWrapper>
    </Wrapper>
  )
}

Controls.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  setClickIndex: PropTypes.func.isRequired,
}

export default Controls
