import React from "react"
import PropTypes from "prop-types"

import { Wrapper } from "./Hero.styles"

const Hero = ({ activeIndex }) => {
  return (
    <Wrapper>
      <h1
        className={activeIndex === -1 ? "" : "active"}
        style={{ zIndex: "50" }}
      >
        Learn what’s possible with ProMazo’s approach to building a new type of
        workforce
      </h1>
    </Wrapper>
  )
}

Hero.propTypes = {
  activeIndex: PropTypes.number,
}

export default Hero
