import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 12rem;
  position: relative;
  z-index: 30;
`

export const Slide = styled.div`
  display: flex;
  width: 100%;
  max-width: 1250px;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.8rem;
  @media only screen and (max-width: 860px) {
    flex-direction: column;
  }
`

export const Text = styled.div`
  margin-right: 2rem;
  @media only screen and (max-width: 860px) {
    margin: 0;
    margin-bottom: 4rem;
    max-width: 692px;
  }
  > h2 {
    max-width: 520px;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  > p {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 22px;
    max-width: 460px;
  }
`

export const Graphic = styled.div`
  display: block;
  max-width: ${({ maxW }) => maxW};
  width: 100%;
`
