import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 30;
  @media only screen and (max-width: 782px) {
    flex-direction: column;
    align-items: center;
    > p {
      margin: 0 !important;
    }
  }
  .tagline {
    display: flex;
    align-items: center;
    margin: 14px 22px;
    > p {
      color: #021f71;
      font-weight: 600;
      font-size: 30px;
      max-height: 30px;
      line-height: 1;
      vertical-align: middle;
      font-weight: 700;
      min-width: 100px;
      @media only screen and (max-width: 500px) {
        font-size: 24px;
        min-width: auto;
      }
    }
  }
  .active {
    color: white;
  }
  padding-bottom: 8rem;
  position: relative;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 940px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const Button = styled.div`
  margin: 14px 22px;
  max-width: 260px;
  max-height: 60px;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  z-index: 60;
  align-items: center;
  border-radius: 10px;
  border: 2px solid ${({ borderColor }) => borderColor};
  padding: 12px 0;
  ${({ bgColor }) =>
    bgColor ? `background-color: ${bgColor};` : `background-color: white;`}
  color: ${({ borderColor }) => borderColor};
  outline: 0;
  transition: 300ms ease-in all;
  line-height: 32px;
`
