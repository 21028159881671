import styled from "styled-components"

export const Wrapper = styled.div`
  > div {
    transition: 400ms ease-in all;
    opacity: 0;
    > svg {
      opacity: 0 !important;
    }
  }

  .active-svg {
    opacity: 1;
    > svg {
      opacity: 1 !important;
    }
  }
`
