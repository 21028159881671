import styled from "styled-components"

export const Wrapper = styled.section`
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#021f71")};
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  box-sizing: border-box;
  > p {
    font-weight: 600;
    color: #ffffff;
    font-size: 30px;
    white-space: nowrap;
    max-height: 41px;
    margin-right: 31px;
    padding-left: 29px;
  }
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 11px 0;
  position: relative;
  z-index: 30;
  @media only screen and (max-width: 550px) {
    flex-direction: column;
    > p {
      margin-bottom: 32px;
    }
  }
`
export const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  > * {
    filter: brightness(0) invert(1);
    margin: 12px 31px;
    @media only screen and (max-width: 550px) {
      margin: 1rem;
    }
  }
`
