import React from "react"

import { Wrapper } from "./CtaButton.styles"

const CtaButton = ({ children, outLink, maxW, bgColor }) => {
  return (
    <Wrapper
      maxW={maxW}
      href={outLink || "./"}
      role="button"
      bgColor={bgColor}
      tabIndex={0}
    >
      {children}
    </Wrapper>
  )
}

export default CtaButton
