import styled from "styled-components"

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 12rem 2rem 10rem;

  > p {
    z-index: 30;
  }
  > h1 {
    text-align: center;
    z-index: 30;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 30px;
    @media only screen and (max-width: 750px) {
      font-size: 30px;
    }
  }
`
