import React, { useState, useLayoutEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Fade from "react-reveal"
import { Wrapper, Logos } from "./LogoBar.styles"

let currImages = []

const LogoBar = ({ activeIndex, colorsArr }) => {
  const data = useStaticQuery(graphql`
    query LogoBarQuery {
      ibm: file(relativePath: { eq: "IBM-Logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 77, layout: FIXED)
        }
      }
      kemper: file(relativePath: { eq: "Kemper-Logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 141, layout: FIXED)
        }
      }
      unilever: file(relativePath: { eq: "Unilever-Logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 42, layout: FIXED)
        }
      }
      schwab: file(relativePath: { eq: "Schwab-Logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 214, layout: FIXED)
        }
      }
      # nbc: file(relativePath: { eq: "Nbc-Logo.png" }) {
      #   childImageSharp {
      #     gatsbyImageData(width: 44, layout: FIXED)
      #   }
      # }
      whirlpool: file(relativePath: { eq: "Whirlpool-Logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 143, layout: FIXED)
        }
      }
      loreal: file(relativePath: { eq: "LOreal-logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 143, layout: FIXED)
        }
      }
      protiviti: file(relativePath: { eq: "protiviti.png" }) {
        childImageSharp {
          gatsbyImageData(width: 110, layout: FIXED)
        }
      }
      pepsi: file(relativePath: { eq: "pepsi.png" }) {
        childImageSharp {
          gatsbyImageData(width: 173, layout: FIXED)
        }
      }
      goodwill: file(relativePath: { eq: "goodwill.png" }) {
        childImageSharp {
          gatsbyImageData(width: 55, layout: FIXED)
        }
      }
      baker: file(relativePath: { eq: "baker.png" }) {
        childImageSharp {
          gatsbyImageData(width: 143, layout: FIXED)
        }
      }
      nmg: file(relativePath: { eq: "cvs.png" }) {
        childImageSharp {
          gatsbyImageData(width: 103, layout: FIXED)
        }
      }
      bestbuy: file(relativePath: { eq: "bestbuy.png" }) {
        childImageSharp {
          gatsbyImageData(width: 98, layout: FIXED)
        }
      }
    }
  `)

  const queryImages = Object.keys(data).map(function (k) {
    return data[k]
  })

  const [logos, setLogos] = useState(queryImages.slice(0, 6))

  switch (activeIndex) {
    case 2:
      currImages = queryImages.slice(7)
      break
    case 1:
      currImages = queryImages
        .slice(0, 1)
        .concat(
          data.ibm,
          data.pepsi,
          data.bestbuy,
          data.whirlpool,
          data.unilever
        )
      break
    default:
      currImages = queryImages.slice(0, 7)
      break
  }

  useLayoutEffect(() => {
    setLogos(currImages)
  }, [activeIndex])
  return (
    <Wrapper bgColor={colorsArr[activeIndex]}>
      <p>Trusted by:</p>
      <Logos>
        {logos.map((img, i) => (
          <Fade key={`image-${i}`}>
            <GatsbyImage image={img.childImageSharp.gatsbyImageData} alt="Logo" />
          </Fade>
        ))}
      </Logos>
    </Wrapper>
  )
}

export default LogoBar
