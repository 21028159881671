import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fade } from "react-reveal"

import CtaButton from "../../primitives/CtaButton"
import { Arrow } from "../../primitives/Icons"

import { Wrapper, Slide, Text, Graphic } from "./Slides.styles"

const Slides = ({ activeIndex, slidesArr }) => (
  <Wrapper>
    {slidesArr.map((slide, i) => {
      return (
        i === activeIndex && (
          <Slide key={`slide-${i}`}>
            <Fade duration={1000} delay={200}>
              <Text>
                <h2>{slide.head}</h2>
                <p>{slide.sub}</p>
                <CtaButton
                  maxW="200px"
                  outLink={slide.outLink}
                  bgColor={slide.color}
                >
                  Learn More
                  <Arrow style={{ paddingTop: "4px", paddingLeft: "12px" }} />
                </CtaButton>
              </Text>
            </Fade>
            <Graphic maxW={slide.width}>
              <Fade duration={1200} delay={200}>
                <GatsbyImage
                  image={slide.img}
                  style={{ maxWidth: slide.width, maxHeight: slide.height }}
                  alt=""
                />
              </Fade>
            </Graphic>
          </Slide>
        )
      )
    })}
  </Wrapper>
)

Slides.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  slidesArr: PropTypes.array.isRequired,
}

export default Slides
