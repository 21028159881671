import React from "react"
import PropTypes from "prop-types"
import { Wrapper } from "./DynamicSvg.styles"

const DynamicSvg = ({ activeIndex, styleObj, svgArr }) => {
  const svgIndex = activeIndex === -1 ? 0 : activeIndex
  return (
    <Wrapper>
      {svgArr.map((svg, i) => (
        <div
          key={`svg-${i}`}
          className={i === svgIndex ? "active-svg" : ""}
          style={styleObj}
        >
          {svg}
        </div>
      ))}
    </Wrapper>
  )
}

DynamicSvg.propTypes = {
  activeIndex: PropTypes.number,
  styleObj: PropTypes.object,
}

export default DynamicSvg
