import styled from "styled-components"

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  z-index: 10;
  > svg {
    position: absolute;
  }
  > h1 {
    transition: transform 0.7s ease 0.4s, opacity 0.7s ease 0.3s,
      padding 0.7s ease 0.4s;
    transform: scaleY(1);
    padding-top: 20rem;
    padding-bottom: 6rem;
    padding-right: 1.8rem;
    padding-left: 1.8rem;
    max-width: 1202px;
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    opacity: 1;
    @media only screen and (max-width: 500px) {
      font-size: 30px;
    }
  }

  .active {
    transform: scaleY(0);
    opacity: 0;
    padding: 0;
  }
`
