import React from "react"

import CtaButton from "../../primitives/CtaButton"
import { Wrapper } from "./CtaBlock.styles"
import DynamicSvg from "../../primitives/DynamicSvg"
import {
  BottomLeft1,
  BottomLeft2,
  BottomLeft3,
  BottomRight1,
  BottomRight2,
  BottomRight3,
  BottomRightStatic,
} from "../../primitives/Icons"


const contentArr = [
  {
    link: "https://workforce.promazo.com/contact/",
    text: "Are you ready to hire the best and brightest?",
    buttonText: "Contact us to learn more"
  },
  {
    link: "https://mimino.promazo.com",
    text: "Are you ready to find your dream job?",
    buttonText: "Click to learn more"
  },
  {
    link: "https://mentors.promazo.com/contact/",
    text: "Take the pledge and mentor the next generation",
    buttonText: "Contact us to learn more"
  }
]

const CtaBlock = ({ activeIndex, colorsArr }) => {
  return (
    <Wrapper>
      <BottomRightStatic
        style={{
          top: `-180px`,
          right: `-90px`,
          zIndex: `1`,
          position: "absolute",
        }}
      />
      <DynamicSvg
        svgArr={[<BottomRight1 />, <BottomRight2 />, <BottomRight3 />]}
        activeIndex={activeIndex}
        styleObj={{
          position: "absolute",
          zIndex: "3",
          top: "41px",
          right: "-90px",
        }}
      />
      <DynamicSvg
        svgArr={[
          <BottomLeft1 style={{ opacity: `0` }} />,
          <BottomLeft2 style={{ opacity: `0` }} />,
          <BottomLeft3 style={{ opacity: `0` }} />,
        ]}
        activeIndex={activeIndex}
        styleObj={{
          position: "absolute",
          zIndex: "3",
          bottom: "0",
          left: "0",
        }}
      />
      <h1>
        {activeIndex !== -1 ? contentArr[activeIndex].text :
          "Are you ready to hire the best and brightest?"}
      </h1>
      <CtaButton bgColor={colorsArr[activeIndex]} outLink={activeIndex === - 1 ? contentArr[0].link : contentArr[activeIndex].link}>
        <p style={{ fontSize: "17px", fontWeight: "600", color: "white" }}>
          {activeIndex !== -1 ? contentArr[activeIndex].buttonText : 'Contact us to learn more'}
        </p>
      </CtaButton>
    </Wrapper>
  )
}

export default CtaBlock
