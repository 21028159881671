import styled from "styled-components"

export const Wrapper = styled.a`
  text-decoration: none;

  max-width: ${({ maxW }) => (maxW ? maxW : "260px")};
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
  border: 2px solid ${({ borderColor }) => borderColor};
  padding: 12px 0;
  ${({ bgColor }) =>
    bgColor ? `background-color: ${bgColor};` : `background-color: #021f71;`}
  color: white;
  outline: 0;
  z-index: 50;
`
