import React, { useState } from "react"

import { graphql } from "gatsby"

import {
  TopLeft1,
  TopLeft2,
  TopLeft3,
  TopLeftStatic,
} from "../primitives/Icons"
import DynamicSvg from "../primitives/DynamicSvg"

import Layout from "../features/Layout"
import Seo from "../features/Seo"
import Hero from "../features/Hero"
import Controls from "../features/Controls"
import Slides from "../features/Slides"
import LogoBar from "../primitives/LogoBar"
import CtaBlock from "../features/CtaBlock"

// Image Data
export const query = graphql`
  query ProMazoLandingQuery {
    slide1: file(relativePath: { eq: "slide1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670, quality: 100, layout: CONSTRAINED)
      }
    }
    slide2: file(relativePath: { eq: "slide2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 692, quality: 100, layout: CONSTRAINED)
      }
    }
    slide3: file(relativePath: { eq: "slide3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 727, quality: 100, layout: CONSTRAINED)
      }
    }
    logo: file(relativePath: { eq: "footerG1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 150, quality: 100, layout: FIXED)
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const [clickIndex, setClickIndex] = useState(-1)
  const slidesArr = [
    {
      head:
        "ProMazo's Workforce enables our highly skilled, flexible, and diverse Fellows to be embedded onto corporate teams.",
      sub:
        "Our Workforce is drawn from the top 5% of university students, allowing companies to tap into the brightest minds with the latest skill sets while test driving their next generation of employees.",
      img: data.slide1.childImageSharp.gatsbyImageData,
      height: "461px",
      width: "670px",
      color: "#0052e5",
      outLink: "https://workforce.promazo.com/",
    },
    {
      head:
        "Welcome to MiMino, your personal Digital Career Center. We rethought the entire job search process for modern times.",
      sub:
        "Through MiMino you will be able to organize your career search, gain valuable work experience and be advised by industry leaders how to secure your dream job.",
      img: data.slide2.childImageSharp.gatsbyImageData,
      height: "432px",
      width: "692px",
      color: "#222bb0",
      outLink: "https://mimino.promazo.com/",
    },
    {
      head:
        "The Coronavirus pandemic has devastated traditional recruiting, especially for students from underrepresented backgrounds.",
      sub:
        "Join the program that is reimagining how students can build their networks and rethinking how the next generation of diverse employees is recruited into companies.",
      img: data.slide3.childImageSharp.gatsbyImageData,
      height: "437px",
      width: "727px",
      color: "#38175b",
      outLink: "https://mentors.promazo.com/",
    },
  ]
  const iconsArr = [<TopLeft1 />, <TopLeft2 />, <TopLeft3 />]

  const colorsArr = ["#021f71", "#222bb0", "#38175b"]

  const landingPageData = {
    noSupporters: true,
    footer: {
      content: null,
    },
  }

  return (
    <Layout pageData={landingPageData}>
      <Seo
        title="ProMazo, inc."
        description="ProMazo connects companies with talented college students to perform project-based work during the academic year."
      />

      <main style={{ position: "relative" }}>
        <TopLeftStatic
          style={{
            top: `-140px`,
            left: `90px`,

            position: "absolute",
          }}
        />
        <DynamicSvg
          activeIndex={clickIndex}
          svgArr={iconsArr}
          styleObj={{
            position: "absolute",
            top: "0",
            left: "-170px",
          }}
        />
        <Hero activeIndex={clickIndex} />
        <Controls activeIndex={clickIndex} setClickIndex={setClickIndex} />
        <Slides activeIndex={clickIndex} slidesArr={slidesArr} />
        <LogoBar activeIndex={clickIndex} colorsArr={colorsArr} />
        <CtaBlock activeIndex={clickIndex} colorsArr={colorsArr} />
      </main>
    </Layout>
  )
}

export default IndexPage
